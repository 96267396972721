import React, { useState, useContext, useEffect } from 'react'
import { Link } from 'gatsby'
import { API } from 'aws-amplify'
import {
  Box,
  Grid,
  Card,
  CardHeader,
  CardContent,
  Typography,
  TextField,
  Button,
  IconButton,
  Checkbox,
  FormControlLabel,
  FormLabel,
  FormGroup,
  FormControl,
} from '@mui/material'
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft'
import MUIDataTable from 'mui-datatables'
import { GlobalStateContext } from '../../context/GlobalContextProvider'
import Layout from '../../layouts/index'
import RoleAccessChip from '../../components/attendance/roleAccessChip'
import TagAccessAdd from '../../components/attendance/tagAccessAdd'
import DeleteItem from '../../components/attendance/deleteItem'
import AccessCheck from '../../components/attendance/accessCheck'
import Hider from '../../components/utils/Hider'

export default function Tag({ location }) {
  const [tags, setTags] = useState([])
  const [role, setRole] = useState('')
  const [roleCheck, setroleCheck] = useState(true)
  const initRole = [{ tag: '', companyID: '', access: ['1', '2'] }]
  const [companyRoles, setCompanyRoles] = useState(initRole)
  const appAccessTags = ['デバイス', 'ユーザー', '全体履歴']
  const [tagWarining, setTagWarining] = useState([])
  const { gStripe, gLoginEmployee } = useContext(GlobalStateContext)

  const handleTagsChange = (e) => {
    const index = tags.indexOf(e.target.value)
    if (index === -1) {
      setTags([...tags, e.target.value])
    } else {
      setTags(tags.filter((tag) => tag !== e.target.value))
    }
  }
  const getRole = (companyID) => {
    API.get('Attendance', `/tag`, {
      queryStringParameters: {
        companyID,
      },
    })
      .then((res) => {
        setCompanyRoles(res)
      })
      .catch((err) => console.log(err))
  }
  const changeSingleAccess = (tagSetting, companyID) => {
    API.post('Attendance', `/tag`, {
      body: { ...tagSetting, companyID },
    })
      .then(() => {
        getRole(gStripe.customer.id)
      })
      .catch((err) => console.log(err))
  }

  useEffect(() => {
    //確認global內的stripe customer從初始值變成fetch回來的值，再執行。不然會呈現null
    if (gStripe.customer.id) {
      getRole(gStripe.customer.id)
    }
  }, [gStripe.customer.id])

  return (
    <Layout location={location}>
      <AccessCheck access='ユーザー'>
        <Hider show={gLoginEmployee.loginInfo.access.includes('ロール管理')}>
          <Card sx={{ mb: '15px' }}>
            <CardHeader
              title={
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <IconButton size='small' component={Link} to='/employees'>
                    <KeyboardArrowLeftIcon />
                  </IconButton>
                  <Typography variant='h2' sx={{ m: '0px 10px 0px 0px' }}>
                    新規ロールを追加
                  </Typography>
                </Box>
              }
            />
            <CardContent
              sx={{
                paddingBottom: 'unset',
              }}
            >
              <Grid container spacing={1} alignItems='center'>
                <Grid item xs={6}>
                  <TextField
                    size='small'
                    label='ロールの名称'
                    variant='outlined'
                    fullWidth
                    required
                    error={!roleCheck}
                    helperText={
                      roleCheck ? '' : 'オーナー権限は新たに作成できません'
                    }
                    onChange={(e) => {
                      setRole(e.target.value)
                      setroleCheck(
                        e.target.value === 'オーナー' ||
                          e.target.value === 'マネージャー' ||
                          e.target.value === 'ゲスト'
                          ? false
                          : true
                      )
                    }}
                    value={role}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormControl sx={{ display: 'center', alignItems: 'center' }}>
                    <FormLabel sx={{ mr: '15px' }}>閲覧可能ページ</FormLabel>
                    <FormGroup
                      sx={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'row',
                      }}
                    >
                      {appAccessTags.map((tag) => {
                        return (
                          <FormControlLabel
                            control={
                              <Checkbox
                                onChange={(e) => {
                                  handleTagsChange(e)
                                }}
                                checked={tags.includes(tag)}
                              />
                            }
                            label={tag}
                            value={tag}
                          />
                        )
                      })}
                    </FormGroup>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <Button
                    variant='outlined'
                    size='small'
                    disabled={role === '' || tags.length === 0 || !roleCheck}
                    onClick={() => {
                      API.post('Attendance', `/tag`, {
                        body: {
                          access: tags,
                          companyID: gStripe.customer.id,
                          tag: role,
                        },
                      })
                        .then(() => {
                          setTags([])
                          setRole('')
                          getRole(gStripe.customer.id)
                        })
                        .catch((err) => console.log(err))
                    }}
                  >
                    登録
                  </Button>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Hider>
        <MUIDataTable
          title={<Typography variant='h2'>ロール一覧</Typography>}
          data={companyRoles}
          columns={[
            {
              name: 'tag',
              label: 'ロール',
              options: {
                filter: true,
                filterType: 'dropdown',
                sort: false,
              },
            },
            {
              name: 'access',
              label: '閲覧可能ページ',
              options: {
                filter: false,
                sort: false,
                customBodyRenderLite: (dataIndex) => {
                  const tagSetting = companyRoles[dataIndex]
                  return (
                    <>
                      {tagSetting.access.map((item, index) => {
                        return (
                          <RoleAccessChip
                            id={dataIndex}
                            key={index}
                            handleCheck={() => {
                              //1.前端陣列刪除所點擊的項目
                              let tmp = [...companyRoles]
                              let deleteFromAccess = tagSetting.access.filter(
                                (i) => i !== item
                              )
                              tmp[dataIndex].access = deleteFromAccess
                              setCompanyRoles(tmp)
                              //2.後端覆蓋刪除完的資料
                              changeSingleAccess(
                                { ...tagSetting },
                                gStripe.customer.id
                              )
                            }}
                            label={item}
                            rowChipLength={tagSetting.access.length}
                            tag={tagSetting.tag}
                          />
                        )
                      })}
                      <TagAccessAdd
                        tag={tagSetting}
                        appAccessTags={appAccessTags}
                        changeSingleAccess={changeSingleAccess}
                      />
                    </>
                  )
                },
              },
            },
            {
              name: '削除',
              options: {
                filter: false,
                sort: false,
                empty: true,
                customBodyRenderLite: (dataIndex) => {
                  const tagSetting = companyRoles[dataIndex]
                  return (
                    <>
                      {companyRoles[dataIndex].tag !== 'オーナー' &&
                        companyRoles[dataIndex].tag !== 'マネージャー' &&
                        companyRoles[dataIndex].tag !== 'ゲスト' &&
                        gLoginEmployee.loginInfo.access.includes(
                          'ロール管理'
                        ) === true && (
                          <Hider
                            show={
                              tagWarining.indexOf(
                                companyRoles[dataIndex].tag
                              ) === -1
                            }
                          >
                            <DeleteItem
                              handleCheck={() => {
                                API.del('Attendance', `/tag`, {
                                  body: tagSetting,
                                })
                                  .then((res) => {
                                    // let tmp = [...companyRoles]
                                    // tmp.splice(dataIndex, 1)
                                    // companyRoles[dataIndex] &&
                                    //   setCompanyRoles(tmp)
                                    getRole(gStripe.customer.id)
                                  })
                                  .catch((e) => {
                                    console.log(e, 'e')
                                    setTagWarining([
                                      ...tagWarining,
                                      companyRoles[dataIndex].tag,
                                    ])
                                    console.log('[tag]  錯誤', e)
                                  })
                              }}
                            />
                            <Typography
                              variant='h6'
                              sx={{ color: 'error.main' }}
                            >
                              このロールは現在使用されていますので削除できません。
                            </Typography>
                          </Hider>
                        )}
                    </>
                  )
                },
              },
            },
          ]}
          options={{
            setTableProps: () => {
              return {
                fullWidth: true,
                size: 'small',
              }
            },
            print: false,
            download: false,
            viewColumns: false,
            elevation: 0,
            selectableRows: 'none',
            textLabels: {
              body: {
                noMatch: '検索結果は０件です',
                toolTip: 'ソート',
                columnHeaderTooltip: (column) => `ソート ${column.label}`,
              },
              pagination: {
                next: 'Next Page',
                previous: 'Previous Page',
                rowsPerPage: '表示数',
                displayRows: '/',
              },
              toolbar: {
                search: '検索',
                downloadCsv: 'CSV',
                print: '印刷する',
                viewColumns: '列を表示',
                filterTable: 'フィルター',
              },
              viewColumns: {
                title: '列を表示',
                titleAria: 'Show/Hide Table Columns',
              },
              filter: {
                all: '全て',
                title: 'フィルター',
                reset: 'リセット',
              },
              selectedRows: {
                text: '選択済み',
                delete: '削除',
                deleteAria: 'Delete Selected Rows',
              },
            },
          }}
        />
      </AccessCheck>
    </Layout>
  )
}
